var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"tab-default"},[_c('v-tabs',{attrs:{"flat":"","hide-slider":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item.id,attrs:{"ripple":false}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabContent),function(item){return _c('v-tab-item',{key:item.id},[_c('div',{staticClass:"single-tab-content"},[(item.isDesc)?_c('div',[_c('p',[_vm._v(" "+_vm._s(item.desc)+" ")]),_c('p',[_vm._v(" "+_vm._s(item.desc2)+" ")])]):_vm._e(),(item.beritabencana)?_c('div',{staticClass:"list0style-inner mt--30"},[_c('v-row',{staticClass:"mt--30 mt_sm--40"},[_vm._l((_vm.berita),function(brt){return _c('v-col',{key:brt.id,attrs:{"lg":"3","md":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"im_box mt--30"},[_c('div',{staticClass:"thumbnail"},[_c('router-link',{attrs:{"to":{
                          name: 'Berita Details',
                          params: { id: brt.id },
                        }}},[_c('img',{staticClass:"w-100",attrs:{"src":brt.foto,"alt":"Blog Images"}})])],1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content_heading"},[_c('div',{staticClass:"category_list"},[_c('router-link',{attrs:{"to":{
                          name: 'Berita Details',
                          params: { id: brt.id },
                        }}},[_vm._v(_vm._s(brt.tgl))])],1),_c('h4',{staticClass:"heading-title"},[_c('router-link',{attrs:{"to":{
                          name: 'Berita Details',
                          params: { id: brt.id },
                        }}},[_vm._v(_vm._s(brt.judul))])],1)]),_c('div',{staticClass:"content_footer"},[_c('router-link',{staticClass:"rn-btn btn-opacity",attrs:{"to":{
                          name: 'Berita Details',
                          params: { id: brt.id },
                        }}},[_vm._v(" Selengkapnya ")])],1)]),_c('router-link',{staticClass:"transparent_link",attrs:{"to":{
                          name: 'Berita Details',
                          params: { id: brt.id },
                        }}})],1)])])}),_c('br'),_c('br')],2),_c('div',{staticClass:"portfolio-area ptb--120"},[_c('v-container',[_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.pagination.total},on:{"input":_vm.onePageChange},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}})],1)],1)],1):_vm._e(),(item.beritabpbd)?_c('div',{staticClass:"list0style-inner mt--30"},[_c('v-row',{staticClass:"mt--30 mt_sm--40"},_vm._l((_vm.bencana),function(bpbd){return _c('v-col',{key:bpbd.id,attrs:{"lg":"3","md":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"im_box mt--30"},[_c('div',{staticClass:"thumbnail"},[_c('router-link',{attrs:{"to":{
                          name: 'Berita Details',
                          params: { id: bpbd.id },
                        }}},[_c('img',{staticClass:"w-100",attrs:{"src":bpbd.foto,"alt":"Blog Images"}})])],1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content_heading"},[_c('div',{staticClass:"category_list"},[_c('router-link',{attrs:{"to":{
                          name: 'Berita Details',
                          params: { id: bpbd.id },
                        }}},[_vm._v(_vm._s(bpbd.tgl))])],1),_c('h4',{staticClass:"heading-title"},[_c('router-link',{attrs:{"to":{
                          name: 'Berita Details',
                          params: { id: bpbd.id },
                        }}},[_vm._v(_vm._s(bpbd.judul))])],1)]),_c('div',{staticClass:"content_footer"},[_c('router-link',{staticClass:"rn-btn btn-opacity",attrs:{"to":{
                          name: 'Berita Details',
                          params: { id: bpbd.id },
                        }}},[_vm._v(" Selengkapnya ")])],1)]),_c('router-link',{staticClass:"transparent_link",attrs:{"to":{
                          name: 'Berita Details',
                          params: { id: bpbd.id },
                        }}})],1)])])}),1)],1):_vm._e()])])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }